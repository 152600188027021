<template>
    <main class="main">
        <l-hero-section />
        <!-- <l-service-section /> -->
        <!-- <l-404-section /> -->
        <l-timer />
        <l-what-section />
        <l-earn-section />
        <l-works-section />
        <l-reviews-section />
        <l-map-section />
        <l-news-section />
    </main>
</template>

<script>
import LHeroSection from "@/components/Landing/LHeroSection.vue"
import LWhatSection from "@/components/Landing/LWhatSection.vue"
import LEarnSection from "@/components/Landing/LEarnSection.vue"
import LWorksSection from "@/components/Landing/LWorksSection.vue"
import LMapSection from "@/components/Landing/LMapSection.vue"
import LNewsSection from "@/components/Landing/LNewsSection.vue"
import LTimer from "@/components/Landing/LTimer.vue"
import LReviewsSection from "@/components/Landing/LReviewsSection.vue"
// import LServiceSection from "@/components/Landing/LServiceSection.vue"
// import L404Section from "@/components/Landing/L404Section.vue"

export default {
    name: "LandingPage",
    components: {
        LNewsSection,
        LMapSection,
        LWorksSection,
        LEarnSection,
        LWhatSection,
        LHeroSection,
        LReviewsSection,
        LTimer
        // LServiceSection,
        // L404Section
    }
}
</script>
