<template>
    <section class="works">
        <div class="works__video works__video--top">
            <video autoplay muted loop playsinline preload="auto">
                <source src="/assets/what-effect.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="works__video works__video--bottom">
            <video autoplay muted loop playsinline preload="auto">
                <source src="/assets/what-effect.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="works__container container">
            <h2 class="works__title section-title" data-aos="fade-up" data-aos-duration="900">
                {{ $t("landing.works.title") }}
            </h2>
            <div class="works-top info-banner" data-aos="fade-up" data-aos-duration="900">
                <p class="large-text info-banner__desc" data-aos="fade-up" data-aos-duration="900">
                    <span> {{ $t("appTitle") }} </span>
                    {{ $t("landing.works.description") }}
                </p>
            </div>
            <p class="works-plans large-text" data-aos="fade-up" data-aos-duration="900">
                {{ $t("landing.works.in") }}
                <span> {{ $t("landing.works.force") }} </span>
                {{ $t("landing.works.has") }}
                <span> {{ $t("landing.works.matrixPlans") }} </span>
                {{ $t("landing.works.plansDescriptions") }}
            </p>

            <div class="works-banner" data-aos="fade-up" data-aos-duration="900">
                <h3 class="works-banner__title" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.banner.title") }}
                </h3>
                <p class="works-banner__desc" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.banner.description") }}
                </p>
                <div class="works-slider-wrapper">
                    <div class="works-slider swiper">
                        <div class="works__wrapper swiper-wrapper">
                            <div class="works-slide swiper-slide">
                                <div class="works-item">
                                    <div class="works-card" data-aos="fade-up" data-aos-duration="900">
                                        <div class="works-card__inner">
                                            <div class="works-card__tree">
                                                <svg
                                                    width="176"
                                                    height="101"
                                                    viewBox="0 0 176 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        x="64"
                                                        width="48.2192"
                                                        height="48.2192"
                                                        rx="24.1096"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="20.4922"
                                                        y="48.6895"
                                                        width="28.9315"
                                                        height="28.9315"
                                                        rx="14.4658"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="126.424"
                                                        y="48.6895"
                                                        width="28.9315"
                                                        height="28.9315"
                                                        rx="14.4658"
                                                        fill="white"
                                                    />
                                                    <line
                                                        x1="36.3778"
                                                        y1="49.2205"
                                                        x2="68.038"
                                                        y2="32.8041"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="0.5"
                                                        y1="-0.5"
                                                        x2="36.1632"
                                                        y2="-0.5"
                                                        transform="matrix(-0.887755 -0.460317 -0.460317 0.887755 139.836 49.8945)"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="8.49622"
                                                        y1="84.1489"
                                                        x2="23.4041"
                                                        y2="71.5345"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="114.58"
                                                        y1="84.1489"
                                                        x2="129.488"
                                                        y2="71.5345"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="0.5"
                                                        y1="-0.5"
                                                        x2="20.0286"
                                                        y2="-0.5"
                                                        transform="matrix(-0.763386 -0.645942 -0.645942 0.763386 61.4785 84.8535)"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="0.5"
                                                        y1="-0.5"
                                                        x2="20.0286"
                                                        y2="-0.5"
                                                        transform="matrix(-0.763386 -0.645942 -0.645942 0.763386 167.562 84.8535)"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <path
                                                        d="M83.367 19.242L80.189 25.318V29H78.915V25.318L75.723 19.242H77.137L79.545 24.184L81.953 19.242H83.367ZM88.0568 29.126C87.3381 29.126 86.6848 28.9627 86.0968 28.636C85.5181 28.3093 85.0608 27.8473 84.7248 27.25C84.3981 26.6433 84.2348 25.9433 84.2348 25.15C84.2348 24.366 84.4028 23.6753 84.7388 23.078C85.0841 22.4713 85.5508 22.0093 86.1388 21.692C86.7268 21.3653 87.3848 21.202 88.1128 21.202C88.8408 21.202 89.4988 21.3653 90.0868 21.692C90.6748 22.0093 91.1368 22.4667 91.4728 23.064C91.8181 23.6613 91.9908 24.3567 91.9908 25.15C91.9908 25.9433 91.8135 26.6433 91.4588 27.25C91.1135 27.8473 90.6421 28.3093 90.0448 28.636C89.4475 28.9627 88.7848 29.126 88.0568 29.126ZM88.0568 28.006C88.5141 28.006 88.9435 27.8987 89.3448 27.684C89.7461 27.4693 90.0681 27.1473 90.3108 26.718C90.5628 26.2887 90.6888 25.766 90.6888 25.15C90.6888 24.534 90.5675 24.0113 90.3248 23.582C90.0821 23.1527 89.7648 22.8353 89.3728 22.63C88.9808 22.4153 88.5561 22.308 88.0988 22.308C87.6321 22.308 87.2028 22.4153 86.8108 22.63C86.4281 22.8353 86.1201 23.1527 85.8868 23.582C85.6535 24.0113 85.5368 24.534 85.5368 25.15C85.5368 25.7753 85.6488 26.3027 85.8728 26.732C86.1061 27.1613 86.4141 27.4833 86.7968 27.698C87.1795 27.9033 87.5995 28.006 88.0568 28.006ZM100.47 21.328V29H99.1959V27.866C98.9532 28.258 98.6126 28.566 98.1739 28.79C97.7446 29.0047 97.2686 29.112 96.7459 29.112C96.1486 29.112 95.6119 28.9907 95.1359 28.748C94.6599 28.496 94.2819 28.1227 94.0019 27.628C93.7312 27.1333 93.5959 26.5313 93.5959 25.822V21.328H94.8559V25.654C94.8559 26.41 95.0472 26.9933 95.4299 27.404C95.8126 27.8053 96.3352 28.006 96.9979 28.006C97.6792 28.006 98.2159 27.796 98.6079 27.376C98.9999 26.956 99.1959 26.3447 99.1959 25.542V21.328H100.47Z"
                                                        fill="#EF9E40"
                                                    />
                                                    <rect
                                                        y="83.6484"
                                                        width="16.8767"
                                                        height="16.8767"
                                                        rx="8.43836"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="106.084"
                                                        y="83.6484"
                                                        width="16.8767"
                                                        height="16.8767"
                                                        rx="8.43836"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="53.041"
                                                        y="83.6484"
                                                        width="16.8767"
                                                        height="16.8767"
                                                        rx="8.43836"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="159.123"
                                                        y="83.6484"
                                                        width="16.8767"
                                                        height="16.8767"
                                                        rx="8.43836"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                            <div class="works-card__footer">
                                                <div class="works-card__count">
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.625 17.5C12.7661 17.5 15.3125 14.9536 15.3125 11.8125C15.3125 8.67138 12.7661 6.125 9.625 6.125C6.48388 6.125 3.9375 8.67138 3.9375 11.8125C3.9375 14.9536 6.48388 17.5 9.625 17.5Z"
                                                            stroke="white"
                                                            stroke-miterlimit="10"
                                                        />
                                                        <path
                                                            d="M16.9961 6.33281C17.4989 6.19645 18.0173 6.12659 18.5383 6.125C20.0467 6.125 21.4933 6.72422 22.56 7.79083C23.6266 8.85744 24.2258 10.3041 24.2258 11.8125C24.2258 13.3209 23.6266 14.7676 22.56 15.8342C21.4933 16.9008 20.0467 17.5 18.5383 17.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M1.75 21.591C2.63807 20.3273 3.81723 19.2958 5.18788 18.5838C6.55854 17.8717 8.08043 17.5 9.625 17.5C11.1696 17.5 12.6915 17.8717 14.0621 18.5838C15.4328 19.2958 16.6119 20.3273 17.5 21.591"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M18.5391 17.5C20.0838 17.499 21.606 17.8703 22.9768 18.5824C24.3476 19.2944 25.5266 20.3263 26.4141 21.5906"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                    <span>6</span>
                                                </div>
                                                <div class="works-card__repeat">
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21.875 9.625L24.5 7L21.875 4.375"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M3.5 14C3.50289 12.1444 4.24132 10.3656 5.55345 9.05345C6.86558 7.74132 8.64437 7.00289 10.5 7H24.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M6.125 18.375L3.5 21L6.125 23.625"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M24.5 14C24.4971 15.8556 23.7587 17.6344 22.4466 18.9466C21.1344 20.2587 19.3556 20.9971 17.5 21H3.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                    <span>1</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="works-item__right">
                                        <h4 class="works-item__title" data-aos="fade-up" data-aos-duration="900">
                                            {{ $t("landing.works.matrix.title") }}
                                        </h4>
                                        <ul class="works-list">
                                            <li class="works-list__item" data-aos="fade-up" data-aos-duration="900">
                                                {{ $t("landing.works.matrix.firstItem") }}
                                            </li>
                                            <li class="works-list__item" data-aos="fade-up" data-aos-duration="900">
                                                {{ $t("landing.works.matrix.secondItem") }}
                                            </li>
                                        </ul>
                                        <p class="works-item__in" data-aos="fade-up" data-aos-duration="900">
                                            {{ $t("landing.works.profit") }} 300%
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="works-slide swiper-slide">
                                <div class="works-item">
                                    <div class="works-card" data-aos="fade-up" data-aos-duration="900">
                                        <div class="works-card__inner">
                                            <div class="works-card__tree">
                                                <svg
                                                    width="176"
                                                    height="101"
                                                    viewBox="0 0 176 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        x="64"
                                                        width="48.2192"
                                                        height="48.2192"
                                                        rx="24.1096"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        y="72"
                                                        width="28.9315"
                                                        height="28.9315"
                                                        rx="14.4658"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="74"
                                                        y="72"
                                                        width="28.9315"
                                                        height="28.9315"
                                                        rx="14.4658"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="147"
                                                        y="72"
                                                        width="28.9315"
                                                        height="28.9315"
                                                        rx="14.4658"
                                                        fill="white"
                                                    />
                                                    <line
                                                        x1="16.1047"
                                                        y1="71.3007"
                                                        x2="68.0127"
                                                        y2="32.9131"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="160.302"
                                                        y1="72.111"
                                                        x2="107.399"
                                                        y2="33.7162"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <line
                                                        x1="88.5"
                                                        y1="72.5"
                                                        x2="88.5"
                                                        y2="47.5"
                                                        stroke="white"
                                                        stroke-linecap="round"
                                                        stroke-dasharray="5 5"
                                                    />
                                                    <path
                                                        d="M83.367 19.242L80.189 25.318V29H78.915V25.318L75.723 19.242H77.137L79.545 24.184L81.953 19.242H83.367ZM88.0568 29.126C87.3381 29.126 86.6848 28.9627 86.0968 28.636C85.5181 28.3093 85.0608 27.8473 84.7248 27.25C84.3981 26.6433 84.2348 25.9433 84.2348 25.15C84.2348 24.366 84.4028 23.6753 84.7388 23.078C85.0841 22.4713 85.5508 22.0093 86.1388 21.692C86.7268 21.3653 87.3848 21.202 88.1128 21.202C88.8408 21.202 89.4988 21.3653 90.0868 21.692C90.6748 22.0093 91.1368 22.4667 91.4728 23.064C91.8181 23.6613 91.9908 24.3567 91.9908 25.15C91.9908 25.9433 91.8135 26.6433 91.4588 27.25C91.1135 27.8473 90.6421 28.3093 90.0448 28.636C89.4475 28.9627 88.7848 29.126 88.0568 29.126ZM88.0568 28.006C88.5141 28.006 88.9435 27.8987 89.3448 27.684C89.7461 27.4693 90.0681 27.1473 90.3108 26.718C90.5628 26.2887 90.6888 25.766 90.6888 25.15C90.6888 24.534 90.5675 24.0113 90.3248 23.582C90.0821 23.1527 89.7648 22.8353 89.3728 22.63C88.9808 22.4153 88.5561 22.308 88.0988 22.308C87.6321 22.308 87.2028 22.4153 86.8108 22.63C86.4281 22.8353 86.1201 23.1527 85.8868 23.582C85.6535 24.0113 85.5368 24.534 85.5368 25.15C85.5368 25.7753 85.6488 26.3027 85.8728 26.732C86.1061 27.1613 86.4141 27.4833 86.7968 27.698C87.1795 27.9033 87.5995 28.006 88.0568 28.006ZM100.47 21.328V29H99.1959V27.866C98.9532 28.258 98.6126 28.566 98.1739 28.79C97.7446 29.0047 97.2686 29.112 96.7459 29.112C96.1486 29.112 95.6119 28.9907 95.1359 28.748C94.6599 28.496 94.2819 28.1227 94.0019 27.628C93.7312 27.1333 93.5959 26.5313 93.5959 25.822V21.328H94.8559V25.654C94.8559 26.41 95.0472 26.9933 95.4299 27.404C95.8126 27.8053 96.3352 28.006 96.9979 28.006C97.6792 28.006 98.2159 27.796 98.6079 27.376C98.9999 26.956 99.1959 26.3447 99.1959 25.542V21.328H100.47Z"
                                                        fill="#EF9E40"
                                                    />
                                                </svg>
                                            </div>
                                            <div class="works-card__footer">
                                                <div class="works-card__count">
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.625 17.5C12.7661 17.5 15.3125 14.9536 15.3125 11.8125C15.3125 8.67138 12.7661 6.125 9.625 6.125C6.48388 6.125 3.9375 8.67138 3.9375 11.8125C3.9375 14.9536 6.48388 17.5 9.625 17.5Z"
                                                            stroke="white"
                                                            stroke-miterlimit="10"
                                                        />
                                                        <path
                                                            d="M16.9961 6.33281C17.4989 6.19645 18.0173 6.12659 18.5383 6.125C20.0467 6.125 21.4933 6.72422 22.56 7.79083C23.6266 8.85744 24.2258 10.3041 24.2258 11.8125C24.2258 13.3209 23.6266 14.7676 22.56 15.8342C21.4933 16.9008 20.0467 17.5 18.5383 17.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M1.75 21.591C2.63807 20.3273 3.81723 19.2958 5.18788 18.5838C6.55854 17.8717 8.08043 17.5 9.625 17.5C11.1696 17.5 12.6915 17.8717 14.0621 18.5838C15.4328 19.2958 16.6119 20.3273 17.5 21.591"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M18.5391 17.5C20.0838 17.499 21.606 17.8703 22.9768 18.5824C24.3476 19.2944 25.5266 20.3263 26.4141 21.5906"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                    3
                                                </div>
                                                <div class="works-card__repeat">
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21.875 9.625L24.5 7L21.875 4.375"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M3.5 14C3.50289 12.1444 4.24132 10.3656 5.55345 9.05345C6.86558 7.74132 8.64437 7.00289 10.5 7H24.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M6.125 18.375L3.5 21L6.125 23.625"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M24.5 14C24.4971 15.8556 23.7587 17.6344 22.4466 18.9466C21.1344 20.2587 19.3556 20.9971 17.5 21H3.5"
                                                            stroke="white"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                    1
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="works-item__right">
                                        <h4 class="works-item__title" data-aos="fade-up" data-aos-duration="900">
                                            {{ $t("landing.works.secondMatrix.title") }}
                                        </h4>
                                        <ul class="works-list">
                                            <li class="works-list__item" data-aos="fade-up" data-aos-duration="900">
                                                {{ $t("landing.works.secondMatrix.firstItem") }}
                                            </li>
                                            <li class="works-list__item" data-aos="fade-up" data-aos-duration="900">
                                                {{ $t("landing.works.secondMatrix.secondItem") }}
                                            </li>
                                        </ul>
                                        <p class="works-item__in" data-aos="fade-up" data-aos-duration="900">
                                            {{ $t("landing.works.profit") }} 200%
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination works-slider-pagination"></div>
                    </div>
                    <div class="works-slider-button swiper-button-prev"></div>
                    <div class="works-slider-button swiper-button-next"></div>
                </div>
            </div>
            <div class="works-banner" data-aos="fade-up" data-aos-duration="900">
                <h3 class="works-banner__title" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.forceBanner.title") }}
                </h3>
                <p class="works-banner__desc" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.forceBanner.description") }}
                </p>
                <p class="works-banner__soon" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.soon") }}
                </p>
            </div>
            <div class="works-banner" data-aos="fade-up" data-aos-duration="900">
                <h3 class="works-banner__title" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.boost.title") }}
                </h3>
                <p class="works-banner__desc" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.boost.description") }}
                </p>
                <p class="works-banner__soon" data-aos="fade-up" data-aos-duration="900">
                    {{ $t("landing.works.soon") }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LWorksSection"
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";
// @TODO return swiper
//@import "swiper/css";
.works {
    padding-bottom: 150px;
    position: relative;
    &::after {
        content: "";
        top: 20px;
        left: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 1, 1, 0) 0%, #010101 48.32%);
        z-index: 2;
        transform: rotate(-180deg);
    }
    &::before {
        content: "";
        bottom: 0;
        right: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 1, 1, -0.925879) 0%, #010101 48.32%);
        z-index: 2;
    }
    &__video {
        background-color: transparent;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: transparent;
        }
        &--top {
            position: absolute;
            top: 100px;
            left: 0;
            width: 70%;
            height: 50%;
            @include mobile-s {
                height: 30%;
            }
        }
        &--bottom {
            transform: rotate(-180deg);
            position: absolute;
            bottom: 100px;
            right: 0;
            width: 70%;
            height: 50%;
            @include mobile-s {
                height: 30%;
            }
        }
    }
    &__container {
        position: relative;
        z-index: 3;
    }
    @include mobile {
        padding-bottom: 90px;
    }
    &-plans {
        max-width: 730px;

        span {
            font-weight: 500;
        }
    }

    &-top {
        margin-bottom: 40px;
    }

    &-plans {
        margin: 0 auto 50px;
    }

    &-items {
        max-width: 730px;
        margin: 0 auto 60px;
    }
    &__wrapper {
        flex-direction: column;
        @media screen and (max-width: 576px) {
            flex-direction: row;
        }
    }
    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        @include mobile-s {
            display: flex;
            gap: 0;
        }

        &__item {
            background-color: #22140c;

            border-radius: 20px;
            padding: 30px 15px;
            display: flex;
            flex-direction: column;
            height: 100%;

            &--light {
                background-color: #926530;
            }

            &--lighter {
                background-color: #cf8835;
            }
        }

        &__title {
            font-weight: 400;
            font-size: var(--font-size-l);
            line-height: 23px;
            margin-bottom: 20px;
        }

        &-list {
            list-style-type: disc;
            padding-left: 30px;
            margin-bottom: 20px;
        }

        &__profit {
            margin-top: auto;
            font-weight: 600;
            font-size: var(--font-size-m);
            line-height: 21px;
            font-variant-numeric: lining-nums;
        }
    }

    &-slider-wrapper {
        position: relative;
    }
    &-slider-button {
        display: none;
        &:after {
            color: #eeb445;
            font-size: 24px;
        }
        @include mobile-s {
            position: absolute;
            top: 45%;
            display: block;
            width: 12px;
            height: 21px;
            z-index: 5;

            &.swiper-button-prev {
                left: 40px;
                &:after {
                    content: "<";
                }
            }
            &.swiper-button-next {
                right: 40px;
                &:after {
                    content: ">";
                }
            }
        }
        @media screen and (max-width: 476px) {
            &.swiper-button-prev {
                left: 20px;
            }
            &.swiper-button-next {
                right: 20px;
            }
        }
        @media screen and (max-width: 400px) {
            &.swiper-button-prev {
                left: -20px;
            }
            &.swiper-button-next {
                right: -20px;
            }
        }
    }
    &-banner {
        margin: 0 auto 40px;
        max-width: 730px;
        width: 100%;
        padding: 40px;
        background: rgba(34, 20, 12, 0.8);
        border-radius: 20px;
        &__title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-jafa);
            margin-bottom: 20px;
            text-align: center;
        }
        &__desc {
            font-weight: 300;
            font-size: 18px;
            line-height: 130%;
            text-align: center;
        }
        &__soon {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 18px;
            margin-top: 15px;
            text-align: center;
            line-height: 130%;
        }
    }
    &-item {
        display: flex;
        align-items: center;
        @media screen and (max-width: 576px) {
            flex-direction: column;
            background: #010101;
            border-radius: 20px;
            max-width: 215px;
            width: 100%;
            margin: 0 auto;
            &__right {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 16px;
            }
        }
        &__title {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 20px;
            @media screen and (max-width: 576px) {
                margin-bottom: 10px;
            }
        }
        &__in {
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-jafa);
            @media screen and (max-width: 576px) {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    &-slide {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
    &-list {
        margin-bottom: 20px;
        padding-left: 20px;
        list-style-type: disc;
        @media screen and (max-width: 576px) {
            margin-bottom: 10px;
            max-width: 183px;
        }
        &__item {
            font-weight: 300;
            font-size: 16px;
            line-height: 130%;
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
    }
    &-card {
        background: linear-gradient(180deg, #ef9e40 0%, rgba(239, 158, 64, 0.3) 100%);
        padding: 1px;
        width: 216px;
        height: 216px;
        margin-right: 30px;
        border-radius: 20px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
        }
        &__inner {
            height: 100%;
            width: 100%;
            padding: 19px;
            border-radius: 20px;
            background-color: #010101;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            background-image: url("/assets/images/landing/matrix-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        &__tree {
            margin-bottom: 15px;
        }
        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        &__count,
        &__repeat {
            display: flex;
            align-items: center;

            svg {
                margin-right: 2px;
            }
        }
    }
    &-slider {
        margin-top: 30px;
    }
}
</style>
