<template>
    <section class="hero">
        <LVideo class="hero-video" :mp4="vid" />
        <div class="hero__container container">
            <p class="hero__suptitle">Meta</p>
            <h1 class="hero__title">{{ $t("appTitle") }}</h1>
            <p class="hero__desc description">{{ $t("landing.hero.description") }}</p>
            <router-link class="hero__connect glob-btn glob-btn--primary" :to="connectLink">
                {{ $t("landing.hero.connect") }}
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 9L20 9"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M14 1L21 9L14 17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </router-link>
            <div class="hero__animal"><img :src="animal" alt="animal" /></div>
            <div class="hero-grid">
                <div class="hero-grid__item">
                    <div class="hero-grid__left">
                        <img :src="firstHeroGrid" alt="firstHeroGrid" />
                    </div>
                    <p class="hero-grid__right large-text">
                        {{ $t("landing.hero.firstGrid") }}
                    </p>
                </div>
                <div class="hero-grid__item">
                    <div class="hero-grid__left">
                        <img :src="secondHeroGrid" alt="secondHeroGrid" />
                    </div>
                    <p class="hero-grid__right large-text hero-grid__right--wd">
                        {{ $t("landing.hero.secondGrid") }}
                    </p>
                </div>
                <div class="hero-grid__item">
                    <div class="hero-grid__left">
                        <img :src="thirdHeroGrid" alt="thirdHeroGrid" />
                    </div>
                    <p class="hero-grid__right large-text">
                        {{ $t("landing.hero.thirdGrid") }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LVideo from "./partials/LVideo.vue"
import { mapGetters } from "vuex"
import { getLocalStorageElement } from "@/helpers/localstorage"
import { LSTORAGE } from "@/config/constants"

export default {
    data() {
        return {
            vid: "/assets/bg-animation.mp4"
        }
    },
    name: "LHeroSection",
    components: {
        LVideo
    },
    computed: {
        isUserConnected() {
            return this.isConnected || getLocalStorageElement(LSTORAGE.connected)
        },
        connectLink() {
            if (this.isUserConnected) {
                return "/dashboard"
            } else {
                return "/signup"
            }
        },
        animal() {
            return require("@/assets/images/landing/animal.png")
        },
        firstHeroGrid() {
            return require("@/assets/images/landing/hero-grid-1.png")
        },
        secondHeroGrid() {
            return require("@/assets/images/landing/hero-grid-2.png")
        },
        thirdHeroGrid() {
            return require("@/assets/images/landing/hero-grid-3.png")
        },
        ...mapGetters("crypto", ["isConnected"])
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";
.hero {
    padding: 185px 0 180px;
    // background-image: url("@/assets/images/landing/bg-animation.mp4");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: 100% 150%;
    &::after {
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 1, 1, -0.925879) 0%, #010101 48.32%);
    }
    &-video {
        position: absolute;
        right: 0;
        bottom: 100px;
        width: 100%;
        height: 100%;
        @include tablet {
            bottom: 200px;
        }
    }

    @include tablet {
        padding: 150px 0;
    }

    @include mobile {
        padding: 80px 0;
        background-image: url("/assets/images/landing/hero-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center 30%;
        position: relative;
        &:after {
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 180px;
            position: absolute;
            background: linear-gradient(180deg, rgba(1, 1, 1, 0.0741213) 0%, #010101 48.32%);
            z-index: 1;
        }
    }
    @media screen and (max-width: 576px) {
        background-position: center 20%;
        padding: 57px 0 84px;
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__animal {
        position: absolute;
        top: -50px;
        right: 100px;

        @include desktop {
            width: 500px;
            right: -15px;
            top: 0px;
        }

        @include tablet {
            width: 400px;
            right: 0;
        }

        @include mobile {
            top: -25px;
            width: 50%;
            right: calc(50% - 310px);
        }

        @include mobile-s {
            top: -12px;
            width: 50%;
            right: -10px;
        }

        @media screen and (max-width: 400px) {
            top: 0px;
            width: 178px;
            right: 0;
        }
    }
    &__suptitle {
        font-family: "Dodger-super-Italic", sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 80px;
        line-height: 71px;
        text-transform: uppercase;
        @media tablet {
            font-size: 60px;
            line-height: 40px;
        }
        @include mobile {
            font-size: 50px;
            line-height: 30px;
        }
        @include mobile-s {
            font-size: 10vw;
            line-height: 30px;
        }
    }
    &__title {
        font-family: "Dodger-super-Italic", sans-serif;
        font-style: italic;
        font-size: 132px;
        line-height: 72px;
        text-transform: uppercase;
        margin-bottom: 25px;
        // fixed
        @include tablet {
            font-size: 100px;
            line-height: 60px;
        }
        @include mobile {
            font-size: 90px;
        }
        @include mobile-s {
            font-size: 14vw;
            line-height: 60px;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 476px) {
            line-height: 35px;
        }
        @media screen and (max-width: 400px) {
            font-size: 55px;
            line-height: 38px;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 370px) {
            font-size: 44px;
        }
    }

    &__desc {
        max-width: 540px;
        margin-bottom: 49px;
        @include tablet {
            margin-bottom: 20px !important;
            max-width: 400px;
        }

        @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 30px !important;
            max-width: 300px;
        }

        @media screen and (max-width: 400px) {
            max-width: 213px;
            margin-bottom: 50px !important;
        }
    }

    &__connect {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 110px;
        min-width: 305px;
        transition: background 0.25s ease-in-out, transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

        svg {
            margin-left: 7px;
            transition: transform 0.25s ease-in-out;
        }
        &:hover svg {
            transform: translateX(10px);
        }
        @include tablet {
            margin-bottom: 50px;
        }

        @include mobile {
            margin-bottom: 100px;
            max-width: 130px;
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @include tablet {
            gap: 15px;
            grid-template-columns: 1fr;
        }

        @include mobile {
            grid-template-columns: 1fr;
        }

        &__item {
            padding: 30px 20px;
            display: flex;
            align-items: center;
            background-color: rgba(14, 14, 14, 0.9);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 20px rgba(1, 1, 1, 0.28);
            border-radius: 20px;

            @include mobile-s {
                padding: 10px 20px;
                min-height: 92px;
                height: 100%;
            }
        }

        &__right--wd {
            max-width: 216px;
            @include tablet {
                max-width: 100%;
            }
        }

        &__left {
            flex-shrink: 0;
            margin-right: 15px;
            width: 54px;
            height: 54px;

            @include mobile-s {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__right {
            @include mobile-s {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
</style>
