<template>
    <section class="earn" id="earn">
        <div class="earn__container container ellipse-a">
            <h2 class="earn__title section-title" data-aos="fade-up" data-aos-duration="500">
                {{ $t("landing.earn.title") }}
            </h2>
            <div class="earn-grid">
                <div class="earn-grid__item">
                    <div class="earn-grid__image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                        <img :src="firstEarn" alt="firstEarn" />
                    </div>
                    <p class="earn-grid__desc" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        {{ $t("landing.earn.firstGrid") }}
                    </p>
                    <div class="earn-grid__step" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100">
                        <div class="earn-grid__count">01</div>
                        <div class="earn-grid__line"></div>
                    </div>
                </div>
                <div class="earn-grid__item">
                    <div class="earn-grid__image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                        <img :src="secondEarn" alt="secondEarn" />
                    </div>
                    <p class="earn-grid__desc" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                        {{ $t("landing.earn.secondGrid") }}
                    </p>
                    <div class="earn-grid__step" data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">
                        <div class="earn-grid__count">02</div>
                        <div class="earn-grid__line"></div>
                    </div>
                </div>
                <div class="earn-grid__item">
                    <div class="earn-grid__image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                        <img :src="thirdEarn" alt="thirdEarn" />
                    </div>
                    <p class="earn-grid__desc" data-aos="fade-up" data-aos-duration="700" data-aos-delay="500">
                        {{ $t("landing.earn.thirdGrid") }}
                    </p>
                    <div class="earn-grid__step" data-aos="fade-up" data-aos-duration="900" data-aos-delay="600">
                        <div class="earn-grid__count">03</div>
                        <div class="earn-grid__line"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LEarnSection",
    computed: {
        firstEarn() {
            return require("@/assets/images/landing/earn-1.png")
        },
        secondEarn() {
            return require("@/assets/images/landing/earn-2.png")
        },
        thirdEarn() {
            return require("@/assets/images/landing/earn-3.png")
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";

.earn {
    padding-bottom: 150px;
    position: relative;
    @include mobile {
        padding-bottom: 100px;
    }
    &::after {
        content: "";
        top: -200px;
        left: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 1, 1, -0.925879) 0%, #010101 48.32%);
    }
    &__container {
        position: relative;
        z-index: 3;
        &:after {
            z-index: -1;
            bottom: -170px;
            right: 200px;
            @include mobile {
                bottom: 0;
                left: 50%;
            }
        }
    }

    &-grid {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);

        @include mobile {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        &__item {
            display: flex;
            flex-direction: column;
        }

        &__image {
            margin-bottom: 14px;

            @include mobile {
                width: 50px;
                height: 50px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &__desc {
            margin-bottom: 20px !important;
            line-height: 130%;

            span {
                font-weight: 500;
            }
        }

        &__step {
            display: flex;
            align-items: center;
            margin-top: auto;
        }

        &__count {
            font-weight: 500;
            font-size: var(--font-size-l);
            margin-right: 20px;
            font-variant-numeric: lining-nums;
        }

        &__line {
            border-bottom: 1px solid #c5c5c5;
            width: 100%;
        }
    }
}
</style>
