<template>
    <section class="news" id="news">
        <div class="news__container container">
            <h2 class="news__title section-title" data-aos="fade-up" data-aos-duration="900">
                {{ $t("landing.news.title") }}
            </h2>
            <div class="news-items">
                <router-link
                    :to="`/academy/post/${post.id}`"
                    class="news-item"
                    v-for="post in news"
                    :key="post.id"
                    data-aos="fade-up"
                    data-aos-duration="900"
                >
                    <div class="news-item__image">
                        <img :src="post.img" alt="preview" />
                    </div>
                    <div class="news-item__info">
                        <div class="news-item__header">
                            <h3 v-if="$i18n.locale === 'ru'" class="news-item__title large-text">
                                {{ post.name_ru }}
                            </h3>
                            <h3 v-else class="news-item__title large-text">
                                {{ post.name }}
                            </h3>
                            <h3 class="news-item__date large-text">{{ formattedDate(post.created) }}</h3>
                        </div>
                        <div class="news-item__desc">
                            <p v-if="$i18n.locale === 'ru'" class="news-item__txt">
                                {{ post.excerpt_ru }}
                                <router-link :to="`/academy/post/${post.id}`">
                                    {{ $t("academy.readMore") }}
                                </router-link>
                            </p>
                            <p v-else class="news-item__txt">
                                {{ post.excerpt }}
                                <router-link :to="`/academy/post/${post.id}`">
                                    {{ $t("academy.readMore") }}
                                </router-link>
                            </p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "LNewsSection",
    mounted() {
        this.$store.dispatch("fetchLandingNewsPosts", { limit: 3 })
    },
    computed: {
        ...mapGetters({
            news: "allNewsPosts",
        }),
    },
    methods: {
        formattedDate(date) {
            return this.$moment(date * 1000).format("DD MMMM YYYY")
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";

.news {
    &-items {
        margin-bottom: 50px;
    }

    &-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: rgba(14, 14, 14, 0.9);
        backdrop-filter: blur(6px);
        border-radius: 20px;

        @media screen and (max-width: 576px) {
            flex-direction: column;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }

        &:not(:last-child) {
            margin-bottom: 40px;
        }
        a {
            color: var(--color-jafa);
            transition: color 0.25s ease-in-out;
        }

        &__title {
            @media screen and (max-width: 767px) {
                margin-bottom: 4px;
            }
        }

        &__image {
            max-width: 350px;
            width: 100%;
            flex-shrink: 0;
            border-radius: 20px;
            overflow: hidden;
            height: 217px;
            @media screen and (max-width: 767px) {
                width: 40%;
            }
            @media screen and (max-width: 576px) {
                width: 100%;
                height: 179px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__info {
            width: 100%;
            padding: 30px 50px 30px 30px;

            @media screen and (max-width: 760px) {
                padding: 30px 15px;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 29px;

            @media screen and (max-width: 767px) {
                flex-direction: column;
                margin-bottom: 15px;
                align-items: flex-start;
            }
        }

        &__date {
            color: #c5c5c5;
        }

        &__more {
            display: none;
        }

        &__show-more {
            font-size: 16px;
            font-weight: 300;
            line-height: 130%;
            color: var(--color-jafa);
            text-decoration: underline;
        }
    }

    &-more-wrapper {
        display: flex;
        justify-content: center;
    }
}
</style>
