<template>
    <section class="what" id="what">
        <div class="what__video what__video--top">
            <video autoplay muted loop playsinline preload="auto">
                <source src="/assets/what-effect.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="what__video what__video--bottom">
            <video autoplay muted loop playsinline preload="auto">
                <source src="/assets/what-effect.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="what__container container">
            <h2 class="what__title section-title" data-aos="fade-up" data-aos-duration="500">
                {{ $t("landing.what.title") }}
            </h2>
            <div class="what-items">
                <div class="what-item">
                    <p class="what-item__desc large-text" data-aos="fade-up" data-aos-duration="500">
                        <span>{{ $t("appTitle") }}</span>
                        {{ $t("landing.what.description") }}
                        <span>{{ $t("landing.what.smartContracts") }}</span>
                    </p>
                    <p class="what-item__desc large-text" data-aos="fade-up" data-aos-duration="500">
                        {{ $t("landing.what.secondDescription") }}
                        <span>{{ $t("landing.what.decentralized") }}</span>
                        {{ $t("landing.what.secondDescriptionPartTwo") }}
                    </p>
                </div>
                <div class="what-banner info-banner" data-aos="fade-up" data-aos-duration="500">
                    <p class="large-text info-banner__desc" data-aos="fade-up" data-aos-duration="500">
                        <span>{{ $t("landing.what.smartContract") }}</span>
                        {{ $t("landing.what.banner") }}
                    </p>
                </div>
                <div
                    class="what-item large-text what-item-ellipse ellipse-a"
                    data-aos="fade-up"
                    data-aos-duration="500"
                >
                    {{ $t("landing.what.platform") }}
                    <span>{{ $t("appTitle") }}</span>
                    {{ $t("landing.what.item") }}
                </div>
                <div class="info-banner" data-aos="fade-up" data-aos-duration="500">
                    <p class="info-banner__desc large-text" data-aos="fade-up" data-aos-duration="500">
                        <span>{{ $t("landing.what.noRisks") }}</span>
                        {{ $t("landing.what.infoBanner") }}
                    </p>
                </div>
            </div>
            <div class="what-grid ellipse-a what-grid">
                <router-link
                    :to="`/academy/post/${post.id}`"
                    class="what-grid__item"
                    v-for="post in posts"
                    :key="post.id"
                    data-aos="fade-up"
                    data-aos-duration="500"
                >
                    <div class="what-grid__image">
                        <img :src="post.img" alt="preview" />
                    </div>
                    <p v-if="$i18n.locale === 'ru'" class="what-grid__desc large-text">{{ post.name_ru }}</p>
                    <p v-else class="what-grid__desc large-text">{{ post.name }}</p>
                </router-link>
            </div>
            <div class="what-more-wrapper">
                <router-link
                    class="what-more glob-btn glob-btn--secondary"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    to="/academy"
                >
                    {{ $t("landing.what.learnMore") }}
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "LWhatSection",
    mounted() {
        this.$store.dispatch("fetchLandingAcademyPosts", 3)
    },
    computed: {
        ...mapGetters({
            posts: "allSpecificPosts"
        })
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";

.what {
    padding-bottom: 150px;

    position: relative;

    &__video {
        background-color: transparent;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: transparent;
        }
        &--top {
            position: absolute;
            top: 100px;
            left: 0;
            width: 70%;
            height: 50%;
            @include mobile-s {
                height: 30%;
            }
        }
        &--bottom {
            transform: rotate(-180deg);
            position: absolute;
            bottom: 100px;
            right: 0;
            width: 70%;
            height: 50%;
            @include mobile-s {
                height: 30%;
            }
        }
    }
    &__container {
        position: relative;
        z-index: 3;
    }

    &:before {
        content: "";
        top: 20px;
        left: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 1, 1, 0) 0%, #010101 48.32%);
        z-index: 2;
        transform: rotate(-180deg);
    }
    @include bg-cover;

    @include mobile {
        padding-bottom: 100px;
        background-image: none;
    }

    @media screen and (max-width: 576px) {
        position: relative;
    }
    @media screen and (max-width: 576px) {
        &:after {
            top: 11%;
        }
        &::before {
            bottom: 46%;
        }
    }
    &-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;
    }

    &-banner {
        margin-bottom: 40px;
        @include mobile {
            margin-bottom: 30px;
        }
    }

    &-item {
        position: relative;
        z-index: 2;
        max-width: 920px;

        &:not(:last-child) {
            margin-bottom: 40px;

            @include mobile {
                margin-bottom: 30px;
            }
        }

        span {
            font-weight: 500;
        }

        &--bg {
            max-width: 730px;
            padding: 40px;
            background-color: #20130b;
            border-radius: 20px;
        }

        &__desc {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-bottom: 65px;
        @include tablet {
            grid-template-columns: 1fr 1fr;
        }
        @include mobile {
            grid-template-columns: 1fr 1fr;
        }

        @include mobile-s {
            grid-template-columns: 1fr;
            max-width: 300px;
            margin: 0 auto 30px;
        }

        &:after {
            bottom: -100px;
            left: 200px;
            z-index: -1;
        }

        &__image {
            height: 197px;
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 20px;

            @include mobile {
                width: 100%;
                height: 200px;
                margin-bottom: 15px;
            }
            img {
                height: inherit;
                object-fit: cover;
                width: 100%;
            }
        }

        &__desc {
            font-weight: 500;
            transition: color 0.25s ease-in-out;
        }

        &__item:hover &__desc {
            color: var(--color-jafa);
        }
    }

    &-more {
        position: relative;
        z-index: 2;

        &-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    &-ellipse {
        overflow: hidden;

        &:after {
            bottom: 0;
            left: -100px;
        }

        &:before {
            bottom: 0;
            right: -100px;
        }
    }

    &-item-ellipse {
        &:after {
            z-index: -1;
            bottom: -200px;
            left: 0;
        }
    }

    &-banner-ellipse {
        overflow: hidden;

        &:after {
            top: 40px;
            right: -100px;
        }
    }
}
</style>
