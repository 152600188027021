<template>
    <section class="reviews">
        <h2 class="section-title title" data-aos="fade-up" data-aos-duration="900">
            {{ $t("landing.reviews.title") }}
        </h2>
        <div class="container">
            <div
                class="container__video-preview video-preview"
                v-for="(video, index) in mock"
                :key="index"
                data-aos="fade-up"
                data-aos-duration="900"
            >
                <div class="video-preview__image-container">
                    <img
                        @click="openModal(video.url)"
                        class="video-preview__image"
                        :src="getPreviewImage(index)"
                        alt="Review preview"
                    />
                </div>

                <p class="video-preview__author">{{ video.author }}</p>
            </div>
        </div>

        <div class="load-more">
            <router-link
                class="glob-btn glob-btn--secondary"
                data-aos="fade-up"
                data-aos-duration="500"
                :to="{ name: 'reviews' }"
            >
                {{ $t("landing.reviews.more") }}
            </router-link>
        </div>

        <UiModal :visible="isModalVisible" @closeModal="closeModal">
            <div class="modal__container">
                <iframe
                    :src="modalVideoUrl"
                    cellspacing="0"
                    style="border-style: none; width: 100%; height: 100%"
                ></iframe>
            </div>
        </UiModal>
    </section>
</template>

<script>
export default {
    name: "LReviewsSection",
    data() {
        return {
            isModalVisible: false,
            modalVideoUrl: null,
            mock: [
                {
                    url: "https://www.youtube.com/embed/lky30gCn3vY",
                    author: "Lindy Shirries"
                },
                {
                    url: "https://www.youtube.com/embed/hEnBGa88rqY",
                    author: "Isaac Joshua Chiroma"
                },
                {
                    url: "https://www.youtube.com/embed/_tD1zLijM3I",
                    author: "Mulalo"
                },
                {
                    url: "https://www.youtube.com/embed/twciaIo4xEk",
                    author: "Faheem Yaseen"
                },
                {
                    url: "https://www.youtube.com/embed/dqSHWISZZhc",
                    author: "Johnmary"
                },
                {
                    url: "https://www.youtube.com/embed/s1qLOHmtzno",
                    author: "Ruth"
                }
            ]
        }
    },
    methods: {
        openModal(videoUrl) {
            this.modalVideoUrl = videoUrl
            this.isModalVisible = true
        },
        closeModal() {
            this.modalVideoUrl = null
            this.isModalVisible = false
        },
        getPreviewImage(index) {
            // return new URL(`/src/assets/images/reviews/reviews_${index + 1}.png`)
            return require(`@/assets/images/reviews/reviews_${index + 1}.png`)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";
.reviews {
    margin-bottom: 150px;
}

.title {
    text-align: center;
    margin-bottom: 50px;
}
.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    @media screen and (max-width: 1250px) {
        grid-template-columns: 1fr 1fr;
    }
    @include mobile-s {
        grid-template-columns: 1fr;
    }
}

.video-preview {
    &__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        margin-bottom: 20px;
        overflow: hidden;
    }
    &__image {
        width: 100%;
        transition: 0.3s transform;

        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.load-more {
    display: flex;
    justify-content: center;
    margin-top: 150px;
}
::v-deep .modal__inner {
    max-width: 1200px;
    height: 600px;
}
</style>
