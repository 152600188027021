<template>
    <section class="map">
        <div class="map__container container">
            <h2 class="map__title section-title">{{ $t("landing.map.title") }}</h2>
            <div class="timeline-wrapper">
                <div class="timeline">
                    <div class="timeline-line"></div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="500"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.development") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="400"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.firstItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"> </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="600"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.secondItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="700"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom timeline-item__title_active"
                            >
                                {{ $t("landing.map.forsageClassic") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="900"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.forsageBoost") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="800"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.thirdItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"> </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="1000"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.fourthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="1100"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom"
                            >
                                {{ $t("landing.map.marketEntry") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="1300"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.tactileProduct") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="1200"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.fifthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"></span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="1400"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.sixthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="1500"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom"
                            >
                                {{ $t("landing.map.force") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="1700"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.cryptoCoin") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="1600"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.seventhItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"></span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="1800"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.eighthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="1900"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom"
                            >
                                {{ $t("landing.map.tactileProgram") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="2100"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.marketPlace") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="2000"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.seventhItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"></span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="2300"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.eighthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="2300"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom"
                            >
                                {{ $t("landing.map.nftPlatform") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="2500"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.metaverse") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="2400"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.seventhItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span class="timeline-item__top"></span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="2600"
                                class="timeline-item__circle timeline-item__circle--bottom"
                                :content="$t(`landing.map.eighthItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="2700"
                                class="timeline-item__bottom timeline-item__title timeline-item__title--bottom"
                            >
                                {{ $t("landing.map.meta") }}
                            </span>
                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="timeline-item__inner">
                            <span
                                data-aos="fade-in"
                                data-aos-duration="1"
                                data-aos-delay="2900"
                                class="timeline-item__top timeline-item__title timeline-item__title--top"
                            >
                                {{ $t("landing.map.lotto") }}
                            </span>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1"
                                data-aos-delay="2800"
                                class="timeline-item__circle timeline-item__circle--top"
                                :content="$t(`landing.map.seventhItem`)"
                                v-tippy="{ placement: 'bottom', distance: 60 }"
                            ></div>
                            <span class="timeline-item__bottom"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LMapSection"
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";

.map {
    padding-bottom: 150px;
    position: relative;
    z-index: 3;
    &__title {
        margin-bottom: 100px;
    }
    @include tablet {
        padding-bottom: 100px;
    }
}

.timeline {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 20px;
    width: 100%;
    min-height: 139px;

    transition: opacity 0.25s ease-in-out;
    @include desktop {
        padding: 0 30px 0 60px;
    }

    @include desktop {
        padding: 0 30px 0 30px;
        &-wrapper {
            width: 100%;
            overflow-x: scroll;
        }
        width: max-content;
    }

    &-line {
        top: 50%;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--color-white);
        position: absolute;
        transition: width 0.25s ease-in-out;
        @include desktop {
            top: 53%;
            width: 100%;
        }

        @include tablet {
            top: 49%;
            width: 100%;
        }
    }
    &-item__inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 40px 1fr;
        justify-items: center;
        z-index: 2;
        position: relative;

        @include desktop {
            grid-template-rows: 1fr 30px 1fr;
        }
    }

    &-item {
        display: flex;
        position: relative;
        @include tablet {
            position: static;
        }
        &__title {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            position: relative;
            z-index: 1;

            @include tablet {
                font-size: 16px;
                line-height: 20px;
            }

            &--top {
                padding-bottom: 26px;

                @include desktop {
                    padding-bottom: 30px;
                }

                @include tablet {
                    padding-bottom: 22px;
                }
            }

            &--bottom {
                padding-top: 26px;

                @include desktop {
                    padding-top: 30px;
                }

                @include tablet {
                    padding-top: 22px;
                }
            }
        }

        &__open {
            opacity: 0;
        }

        &__circle {
            transform: scale(1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #eeb445 0%, #ef9e40 100%);
            border-radius: 100%;
            border: 2px solid var(--color-white);
            position: relative;

            @include tablet {
                width: 30px;
                height: 30px;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: opacity 0.5s ease-in-out;
            }

            &:after {
                content: "";
                top: 0;
                left: calc(50% - 1px);
                width: 2px;
                height: 22px;
                background-color: var(--color-white);
                position: absolute;

                @include tablet {
                    height: 18px;
                }
            }

            &--none:after {
                display: none;
            }

            &--top:after {
                top: calc(-100% + 12px);

                @include tablet {
                    top: calc(-100% + 8px);
                }
            }

            &--bottom:after {
                top: 100%;
            }
        }

        &__content {
            transition: opacity 0.25s ease-in-out;
            opacity: 0;
            position: absolute;
            min-width: 248px;
            width: 100%;
            padding: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            background: #0e0e0e;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 20px rgba(1, 1, 1, 0.28);
            backdrop-filter: blur(6px);
            border-radius: 5px;
            z-index: 5;
            left: calc(-245px / 2.5);

            @include desktop {
                left: -70px;
            }

            &--top {
                top: -60px;
            }

            &--bottom {
                bottom: -80px;
            }
            @include tablet {
                visibility: hidden;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 184px;
                height: max-content;
                padding: 20px 10px 10px;
            }
        }
    }
    &-close {
        display: none;
        @include tablet {
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}

@keyframes width {
    from {
        width: 0;
    }
    to {
        width: inherit;
    }
}
@keyframes zoom {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.timeline.animation {
    .timeline-line {
        animation: width 3s ease-in-out;
        width: inherit;
    }

    .timeline-item__title {
        &_active {
            color: #fedd00;
        }
    }

    // .timeline-item__circle {
    //     animation: zoom 1s ease-in-out;
    // }

    // .timeline-item__title {
    //     animation: opacity 2s ease-in-out;
    // }
}
</style>
